import React, { Component } from "react";
import Slider from "react-slick";
import Img from "gatsby-image";


// Import css files
import "../styles/slick.scss"
import "slick-carousel/slick/slick-theme.css";



export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const numImages = this.props.images.length;
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: true,
      speed: 500,
    };
    return (
      <div className="slider">
        <div className="grid-cell-inner">
          <Slider
            ref={(c) => (this.slider = c)}
            {...settings}
            className="image-container"
          >
            {this.props.images.map((image, key) => (
              <div className="images">
                <div key={key + 10} className="sliderContainer">
                  {image.localFile && (
                    <div className="imgContainer">
                      <div>
                        <Img
                          fluid={image.localFile.childImageSharp.fluid}
                          imgStyle={{
                            objectPosition: "left top",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {this.props.children}
                <div className="caption">
                  <div className="prev">
                    <button
                      className="button prev screen"
                      onClick={this.previous}
                    >
                    </button>
                  </div>
                  <div className="numbers">
                    <span className="active">{key + 1}</span> /{" "}
                    <span className="total">{numImages}</span>
                  </div>
                  <div className="next">
                    <button className="button next screen" onClick={this.next}>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}
